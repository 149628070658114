import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Table, { TableCell, TableRow } from "@amzn/meridian/table";

import MoveDeviceDialog from "componentsV2/Common/Dialogs/MoveDeviceDialog";
import RemoveDeviceDialog from "componentsV2/Common/Dialogs/RemoveDeviceDialog";
import { keys } from "i18n";

export default function DeviceTable({ yardId, refreshingYardDevices }) {
  const [imeiToMoveOrRemove, setImeiToMoveOrRemove] = useState(null);
  const [shouldShowMoveDeviceDialog, setShouldShowMoveDeviceDialog] = useState(false);
  const [shouldShowRemoveDeviceDialog, setShouldShowRemoveDeviceDialog] = useState(false);

  const yardDevices = useSelector((state) => state.yardDevicesData)[yardId];

  const { t } = useTranslation();

  const openMoveDeviceDialog = (imei) => {
    setImeiToMoveOrRemove(imei);
    setShouldShowMoveDeviceDialog(true);
  };
  const closeMoveDeviceDialog = () => setShouldShowMoveDeviceDialog(false);

  const openRemoveDeviceDialog = (imei) => {
    setImeiToMoveOrRemove(imei);
    setShouldShowRemoveDeviceDialog(true);
  };
  const closeRemoveDeviceDialog = () => setShouldShowRemoveDeviceDialog(false);

  if (refreshingYardDevices) {
    return (
      <Row
        data-testid="refreshYardDevicesLoader"
        spacingInset="medium"
        alignmentHorizontal="center"
      >
        <Loader />
      </Row>
    );
  }

  return (
    <>
      <Box>
        <Table headerRows={1} showDividers={true}>
          <TableRow>
            <TableCell>{t(keys.IMEI)}</TableCell>
            <TableCell>{t(keys.ACTIONS)}</TableCell>
          </TableRow>

          {yardDevices.devices.map(({ imei }) => (
            <TableRow key={`${imei}-row`} highlightOnHover={true}>
              <TableCell data-testid={`${imei}-cell`}>{imei}</TableCell>
              <TableCell data-testid={`${imei}-actions`}>
                <Row>
                  <Button
                    data-testid="moveDeviceButton"
                    type="link"
                    onClick={() => openMoveDeviceDialog(imei)}
                  >
                    {t(keys.MOVE)}
                  </Button>

                  <Button
                    data-testid="removeDeviceButton"
                    type="link"
                    onClick={() => openRemoveDeviceDialog(imei)}
                  >
                    {t(keys.REMOVE)}
                  </Button>
                </Row>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Box>

      {shouldShowMoveDeviceDialog && (
        <MoveDeviceDialog
          yardId={yardId}
          imei={imeiToMoveOrRemove}
          dismiss={closeMoveDeviceDialog}
        />
      )}

      {shouldShowRemoveDeviceDialog && (
        <RemoveDeviceDialog
          yardId={yardId}
          imei={imeiToMoveOrRemove}
          dismiss={closeRemoveDeviceDialog}
        />
      )}
    </>
  );
}

DeviceTable.propTypes = {
  yardId: PropTypes.string.isRequired,
  refreshingYardDevices: PropTypes.bool.isRequired,
};

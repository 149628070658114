import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Column from "@amzn/meridian/column";
import Modal from "@amzn/meridian/modal";
import Text from "@amzn/meridian/text";

import BuildingCodeSearch from "componentsV2/Common/BuildingCodeSearch";
import DialogFooter from "componentsV2/Common/Dialogs/DialogFooter";
import { moveYardDevice } from "store";
import { generateErrorMessage } from "helpers";
import { keys } from "i18n";

export default function MoveDeviceDialog({ yardId, imei, dismiss }) {
  const [targetBuildingCodeSearchString, setTargetBuildingCodeSearchString] = useState("");
  const [targetYardIdentifier, setTargetYardIdentifier] = useState(null);
  const [movingDevice, setMovingDevice] = useState(false);
  const [errorMovingDevice, setErrorMovingDevice] = useState(null);

  const dispatch = useDispatch();

  const yardConfig = useSelector((state) => state.yardConfigData)[yardId];

  const { buildings } = yardConfig || {};

  const { t } = useTranslation();

  useEffect(() => {
    setErrorMovingDevice(null);
  }, [targetYardIdentifier]);

  const handleMoveDevice = () => {
    setMovingDevice(true);
    setErrorMovingDevice(null);

    dispatch(
      moveYardDevice(
        {
          yardIdentifier: {
            yardId,
            buildingCodes: buildings.map(({ buildingCode }) => buildingCode),
          },
          targetYardIdentifier,
          imei,
        },
        dismiss,
        (error) => {
          setMovingDevice(false);
          setErrorMovingDevice(error);
        }
      )
    );
  };

  const error = errorMovingDevice
    ? generateErrorMessage(t(keys.MOVE_DEVICE_ERROR_MESSAGE), errorMovingDevice)
    : null;

  return (
    <Modal width="400px" title={t(keys.MOVE_DEVICE)} open={true}>
      <Column spacing="500">
        <Column>
          <Text>{`${t(keys.DEVICE_TO_MOVE)}: ${imei}.`}</Text>
          <BuildingCodeSearch
            forceUseSearchAttributes
            searchString={targetBuildingCodeSearchString}
            setSearchString={setTargetBuildingCodeSearchString}
            setSelectedYardIdentifier={setTargetYardIdentifier}
          />
        </Column>

        <DialogFooter
          inProgress={movingDevice}
          error={error}
          disabled={!targetBuildingCodeSearchString || !targetYardIdentifier}
          commit={handleMoveDevice}
          dismiss={dismiss}
        />
      </Column>
    </Modal>
  );
}

MoveDeviceDialog.propTypes = {
  yardId: PropTypes.string.isRequired,
  imei: PropTypes.string.isRequired,
  dismiss: PropTypes.func.isRequired,
};

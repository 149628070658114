import { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Column from "@amzn/meridian/column";
import Input from "@amzn/meridian/input";
import Modal from "@amzn/meridian/modal";
import Toggle from "@amzn/meridian/toggle";

import DialogFooter from "componentsV2/Common/Dialogs/DialogFooter";
import { addYardDevice } from "store";
import { generateErrorMessage } from "helpers";
import { keys } from "i18n";
import { DEVICE_IMEI_FORMAT, DEVICE_MAC_ADDRESS_FORMAT } from "app-constants";

export default function AddDeviceDialog({ yardId, dismiss }) {
  const [isMacAddress, setIsMacAddress] = useState(false);
  const [deviceId, setDeviceId] = useState(null);
  const [addingDevice, setAddingDevice] = useState(false);
  const [errorAddingDevice, setErrorAddingDevice] = useState(null);

  const dispatch = useDispatch();

  const yardConfig = useSelector((state) => state.yardConfigData)[yardId];

  const { buildings } = yardConfig || {};

  const { t } = useTranslation();

  const handleIsMacAddressToggle = () => {
    setIsMacAddress(!isMacAddress);
    setDeviceId(null);
  };

  const handleAddDevice = () => {
    setAddingDevice(true);
    setErrorAddingDevice(null);

    dispatch(
      addYardDevice(
        {
          yardIdentifier: {
            yardId,
            buildingCodes: buildings.map(({ buildingCode }) => buildingCode),
          },
          imei: deviceId,
        },
        dismiss,
        (error) => {
          setAddingDevice(false);
          setErrorAddingDevice(error);
        }
      )
    );
  };

  const hasMacAddressFormatError =
    !!deviceId && isMacAddress && !DEVICE_MAC_ADDRESS_FORMAT.test(deviceId);
  const hasImeiFormatError = !!deviceId && !isMacAddress && !DEVICE_IMEI_FORMAT.test(deviceId);

  const error = errorAddingDevice
    ? generateErrorMessage(t(keys.ADD_DEVICE_ERROR_MESSAGE), errorAddingDevice)
    : null;

  return (
    <Modal width="400px" title={t(keys.ADD_DEVICE)} open={true}>
      <Column spacing="500">
        <Column spacing="none">
          <Toggle
            data-testid="isMacAddressToggle"
            checked={isMacAddress}
            onChange={handleIsMacAddressToggle}
          >
            {t(keys.MAC_ADDRESS)}
          </Toggle>

          {isMacAddress && (
            <Input
              data-testid="macAddressField"
              type="text"
              label={t(keys.MAC_ADDRESS)}
              constraintText={t(keys.MAC_ADDRESS_FORMAT)}
              error={hasMacAddressFormatError}
              value={deviceId}
              onChange={setDeviceId}
            />
          )}
          {!isMacAddress && (
            <Input
              data-testid="imeiField"
              type="number"
              label={t(keys.IMEI)}
              constraintText={t(keys.IMEI_FORMAT)}
              error={hasImeiFormatError}
              value={deviceId}
              onChange={setDeviceId}
            />
          )}
        </Column>

        <DialogFooter
          inProgress={addingDevice}
          error={error}
          disabled={!deviceId || hasMacAddressFormatError || hasImeiFormatError}
          commit={handleAddDevice}
          dismiss={dismiss}
        />
      </Column>
    </Modal>
  );
}

AddDeviceDialog.propTypes = {
  yardId: PropTypes.string.isRequired,
  dismiss: PropTypes.func.isRequired,
};
